import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import IconMigros from 'icons/f-migros.svg'
import MigrosIcon from 'icons/migros.svg'
import IconLogo from 'icons/f-logo.svg'
import IconClose from 'icons/s-close.svg'
import IconSad from 'icons/sad-1.svg'
import useBlockScroll from 'lib/useBlockScroll'
import SearchBar from 'components/search/SearchBar'

import { trpc } from '@goodee/trpc-lib/src/trpcClient'
import { BrandMinimal } from 'common-types-lib/src/types/brand/brand'
import SearchResults from 'components/search/SearchResults'
import SearchButton from 'components/search/SearchButton'
import { Category } from 'common-types-lib/src/types/category'
import SearchResult from 'components/search/SearchResult'
import Button from './Button'
import LoginOutButton from 'components/migrosacc/LogInOutButton'
import { useSession, signOut } from 'next-auth/react'
import AccountDesktop from 'components/migrosacc/AccountDesktop'
import AccountMobile from 'components/migrosacc/AccountMobile'

const navItems = [
  { path: '/', id: 'products' },
  { path: '/all-in-one-gutschein', id: 'allInOneGutschein' },
  { path: '/business', id: 'business' },
  { path: '/hilfe', id: 'help' },
]

export type NavItemProps = {
  path: string
  id: string
  route: string
}

const NavItem: React.FC<NavItemProps> = ({ path, id, route }) => {
  const { t } = useTranslation()
  const isCurrent = route === path

  return (
    <li className="">
      <Link
        href={path}
        className={
          isCurrent ? 'text-violet-100' : 'transition h:hover:text-violet-100'
        }
      >
        {t(`header.nav.${id}`)}
      </Link>
    </li>
  )
}

type AppbarMenuProps = {
  isMenuVisible: boolean
}

const AppbarMenu = ({ isMenuVisible }: AppbarMenuProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const blockScroll = useBlockScroll()
  const { pathname } = useRouter()

  const { data: session } = useSession()
  const router = useRouter()

  const search = trpc.searchCatalog.useMutation()
  const categories = trpc.getCategories.useQuery()
  const allBrands = trpc.getAllLiveBrandsMinimal.useQuery()
  const [searchResults, setSearchResults] = useState<BrandMinimal[]>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [isSearchOpen, setIsSearchOpen] = useState(false) // only for mobile
  const [isMigrosAccountOpen, setIsMigrosAccountOpen] = useState(false) // only for mobile

  const handleClick = () => {
    if (session?.user) {
      setIsMigrosAccountOpen(!isMigrosAccountOpen)
    } else {
      router.push('/login')
    }
  }

  const onSearch = (searchTerm: string) => {
    setSearchInput(searchTerm)

    if (searchTerm) {
      searchCatalog(searchTerm).catch(err => {
        alert(
          'Etwas ist fehlgeschlagen. Versuche es in wenigen Momenten erneut'
        )
      })
    } else {
      setSearchResults([])
    }
  }

  const searchCatalog = async (searchTerm: string) => {
    const { data } = await search.mutateAsync({
      search: searchTerm,
    })

    setSearchResults(data)

    // add to google analytics
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'search',
      ecommerce: {
        search_term: searchTerm,
      },
    })
  }

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setOpen(false)
        blockScroll(false)
      }
    }

    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  })

  const togglerNav = () => {
    blockScroll(!open)
    setOpen(!open)
  }

  if (isSearchOpen) {
    return <div></div>
    /*return (
      <div className="fixed z-20 container pt-4 pb-4 top-0 left-0 bg-gray-100 w-full h-full">
        <div className="flex flex-row justify-between items-center">
          <SearchBar onSearchChange={onSearch} />
          <button onClick={() => setIsSearchOpen(false)}>
            <IconClose className="stroke-black w-8 h-8" />
          </button>
        </div>

        {!searchInput && categories.data && allBrands.data && (
          <div className="mt-8 flex flex-col gap-6">
            <h3 className="text-xs font-medium uppercase text-gray-500">
              Beliebt
            </h3>

            <div className="flex flex-col gap-8">
              {(
                categories.data.data.find(
                  val => val.slug === 'popular'
                ) as Category
              ).content.map(id => {
                const brand = allBrands.data.data.find(
                  brand => brand.id === id
                ) as BrandMinimal

                return (
                  <SearchResult
                    brand={brand}
                    isSecondary={true}
                    key={brand.id}
                  />
                )
              })}
            </div>
          </div>
        )}

        {searchInput && searchResults.length > 0 && (
          <div className="flex flex-col gap-2 mt-8">
            {searchResults.map(res => (
              <SearchResult brand={res} key={res.id} />
            ))}
          </div>
        )}

        {searchInput && searchResults.length < 1 && (
          <div className="mt-8 flex flex-col justify-between h-[90%]">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 items-center">
                <IconSad />
                <p className="font-medium">{t('search.noResults.title')}</p>
              </div>
              <span className="ml-10">{t('search.noResults.description')}</span>
            </div>
            {allBrands.data?.data.find(res => res.slug === 'goodee') && (
              <Link
                href="/all-in-one-gutschein"
                className="p-4 bg-brand-100 rounded flex flex-row gap-4"
              >
                <div className="h-12 shadow aspect-[1.6/1]">
                  <img
                    src="https://goodee.blob.core.windows.net/brands/goodee/card.png"
                    alt="Goodee AiO"
                    className="w-full h-full object-cover flex-1"
                  />
                </div>
                {t('search.aio.description')}
              </Link>
            )}
          </div>
        )}
      </div>
    )*/
  } else {
    return (
      <>
        <div
          className={`fixed inset-0 z-20 bg-black transition backdrop-filter backdrop-blur-xl bg-opacity-15 ${
            open ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        ></div>

        <header className="h-15 lg:h-20 flex items-center relative z-30">
          <div className="container flex items-center">
            <Link href="/" className="flex-none relative z-30 flex-1">
              <IconLogo className="lg:w-32 lg:h-9" />
              <span className="sr-only">{t('title')}</span>
            </Link>

            {/*isMenuVisible ? (
              <>
                <SearchButton
                  onClick={() => setIsSearchOpen(true)}
                  className="mr-4 block lg:hidden flex-auto"
                />

                <LoginOutButton
                  className="flex lg:hidden mr-6"
                  onClick={handleClick}
                />

                <button
                  className={`flex-none flex-1 ml-auto block w-8 h-8 transition duration-500 relative z-30 lg:hidden print:!hidden ${
                    open ? 'rotate-180' : ''
                  }`}
                  aria-label={`${
                    open ? t('header.closeNav') : t('header.openNav')
                  }`}
                  onClick={togglerNav}
                >
                  <span
                    className={`w-5 h-0.5 bg-black absolute top-1/2 -mt-1px left-1/2 -translate-x-1/2 transition ${
                      open ? 'translate-y-0 -rotate-45' : '-translate-y-1'
                    }`}
                  ></span>
                  <span
                    className={`w-5 h-0.5 bg-black absolute top-1/2 -mt-1px left-1/2 -translate-x-1/2 transition ${
                      open ? 'translate-y-0 rotate-45' : 'translate-y-1'
                    }`}
                  ></span>
                </button>
              </>
            ) : (
              <div className="print:!hidden py-6 border-opacity-15 border-black text-sm flex items-start justify-center ml-auto lg:border-0 lg:py-0 lg:hidden">
                <span className="mr-3 opacity-45 pt-0.5">Powered by</span>
                <IconMigros className="fill-current text-orange-100" />
              </div>
            )*/}

            <div
              className={`
            fixed top-0 left-0 right-0 pt-30 z-20 rounded-b-2xl shadow-nav bg-white  origin-top transition

            lg:flex lg:flex-auto lg:items-center lg:opacity-100 lg:pointer-events-auto lg:translate-y-0 lg:static lg:pt-0 lg:rounded-b-none lg:shadow-none lg:scale-100

            ${
              open
                ? 'opacity-100 translate-y-0 scale-100'
                : 'opacity-0 -translate-y-20 scale-95 pointer-events-none'
            }`}
            >
              {/* isMenuVisible && (
                <nav className="px-10 pb-16 font-bold text-5xl lg:ml-11 lg:px-0 lg:pb-0 lg:text-md lg:font-medium print:!hidden">
                  <ul className={`space-y-5 lg:flex lg:space-y-0 lg:space-x-6`}>
                    {navItems.map(i => (
                      <NavItem key={i.id} route={pathname} {...i} />
                    ))}
                  </ul>
                </nav>
              ) */}

              {/* 
              <div className="relative py-6 flex flex-row ml-auto items-center justify-center border-t border-opacity-15 border-black lg:border-0 lg:py-0 print:!hidden">
                <SearchBar
                  className="mr-7 hidden lg:block"
                  onSearchChange={onSearch}
                />
                {searchInput && (
                  <SearchResults
                    searchTerm={searchInput}
                    searchResults={searchResults}
                  />
                )}

                <LoginOutButton
                  className="hidden lg:flex"
                  onClick={handleClick}
                />
                {isMigrosAccountOpen && (
                  <AccountDesktop className="hidden lg:flex" />
                )}

                <div className="h-6 text-sm flex lg:hidden items-start justify-center">
                  <span className="mr-3 opacity-45 pt-0.5">Powered by</span>
                  <IconMigros className="fill-current text-orange-100" />
                </div>
              </div>
              */}
            </div>

            <div
              className={`fixed inset-0 z-40 bg-black transition bg-opacity-60 block lg:hidden ${
                isMigrosAccountOpen
                  ? 'opacity-100'
                  : 'opacity-0 pointer-events-none'
              }`}
            ></div>
            <div
              className={`
              fixed bottom-0 left-0 right-0 pt-6 z-50 rounded-t-2xl shadow-nav bg-white  origin-top transition block lg:hidden
              ${
                isMigrosAccountOpen
                  ? 'opacity-100 translate-y-0 scale-100'
                  : 'opacity-0 translate-y-20 scale-95 pointer-events-none'
              }
            `}
            >
              <div className="w-full flex flex-row-reverse px-4">
                <button onClick={() => setIsMigrosAccountOpen(false)}>
                  <IconClose className="stroke-black w-6 h-6" />
                </button>
              </div>
              <AccountMobile />
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default AppbarMenu
