import React, { useRef, useState, useEffect } from 'react'
import { useTranslation, Trans } from 'next-i18next'

export type TextAccordionProps = {
  namespace: string
}

export type itemProps = {
  title: string
  text: string
}

const TextAccordion: React.FC<TextAccordionProps> = ({ namespace }) => {
  const { t } = useTranslation()
  const [current, setCurrent] = useState<number | null>(null)
  const items = t(namespace, { returnObjects: true, defaultValue: [] }) as []

  return (
    <ul className="divide-y divide-solid divide-violet-100 divide-opacity-15 border-b border-violet-100 border-opacity-15">
      {items &&
        items.length &&
        typeof items === 'object' &&
        items.map((content: itemProps, idx: number) => (
          <TextAccordionItem
            current={current}
            setCurrent={setCurrent}
            idx={idx}
            id={`${namespace}-${idx}`}
            key={`${namespace}-${idx}`}
            content={content}
          />
        ))}
    </ul>
  )
}

export type TextAccordionItemProps = {
  current: number | null
  content: itemProps
  id: string
  setCurrent: (current: number | null) => void
  idx: number
}

const TextAccordionItem: React.FC<TextAccordionItemProps> = ({
  current,
  setCurrent,
  idx,
  id,
  content,
}) => {
  const [height, setHeight] = useState(0)
  const [contentText, setContentText] = useState<string>('')
  const ref = useRef<HTMLDivElement>(null)

  const onClick = () => {
    setCurrent(current === idx ? null : idx)
  }

  useEffect(() => {
    if (!ref.current) return
    const h = ref.current

    const calcHeight = () => {
      const rect = h.getBoundingClientRect()
      setHeight(rect.height)
    }

    setTimeout(() => {
      calcHeight()
    }, 2000)

    window.addEventListener('resize', calcHeight)
    return () => window.removeEventListener('resize', calcHeight)
  }, [ref])

  useEffect(() => {
    setContentText(content.text)
  })

  return (
    <li className="">
      <button
        className="text-md flex items-center py-4 w-full text-left font-medium sm:text-lg sm:py-8"
        aria-controls={`${id}-content`}
        aria-expanded={current === idx}
        onClick={onClick}
      >
        <span className="flex-auto pr-2" id={`${id}-header`}>
          {content.title}
        </span>

        <span
          className="
          rounded-full w-9 h-9 relative flex-none flex bg-violet-15
          before:w-4 before:h-0.5 before:bg-violet-100 before:m-auto before:bg-opacity-50 sm:w-12 sm:h-12"
        >
          <span
            className={`w-0.5 h-4 bg-violet-100 bg-opacity-50 transition absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
              current === idx ? 'rotate-90' : ''
            }`}
          ></span>
        </span>
      </button>

      <div
        className="overflow-hidden transition-height duration-300"
        style={{ height: current === idx ? height : '0' }}
        aria-labelledby={`${id}-header`}
        id={`${id}-content`}
      >
        <div ref={ref}>
          <div
            className="typography pb-8 pt-3"
            dangerouslySetInnerHTML={{ __html: contentText }}
          >
            {/* <Trans i18nKey={contentText} /> */}
          </div>
        </div>
      </div>
    </li>
  )
}

export default TextAccordion
