import React, { useState } from 'react'
import { useTranslation, Trans } from 'next-i18next'
import TextAccordion from 'components/common/TextAccordion'
import Link from 'next/link'
import Grid from 'components/common/Grid'

import IconMigros from 'icons/f-migros.svg'
import { Status } from 'common-types-lib/src/types/requestStatus'
import { useForm } from 'react-hook-form'
import { RegisterForNewsletterRequest } from 'pages/api/cmd/registerForNewsletter'
import TextField from 'components/common/form/TextField'
import { emailRegex } from 'lib/emailValidator'
import Button from 'components/common/Button'
import IconCheck from 'icons/s-check.svg'

type RegisterForNewsletterEntity = {
  newsletterEmail: string
}

const Footer = () => {
  const { t } = useTranslation()
  const [registeredForNewsletter, setRegisteredForNewsletter] = useState<
    'initial' | 'loading' | 'sent'
  >('initial')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForNewsletterEntity>()

  const onRegisterForNewsletter = (
    registerForNewsletterEntity: RegisterForNewsletterEntity
  ): void => {
    setRegisteredForNewsletter('loading')
    const request: RegisterForNewsletterRequest = {
      email: registerForNewsletterEntity.newsletterEmail,
    }
    fetch('/api/cmd/registerForNewsletter', {
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then(async res => {
        const response: Status = await res.json()
        if (response?.success) {
          setRegisteredForNewsletter('sent')
        } else {
          alert('Whoopsie! Das ist etwas schief gelaufen.')
        }
      })
      .catch(error => {
        alert('Whoopsie! Das ist etwas schief gelaufen.')
        throw error
      })
  }

  return (
    <footer className="pb-4 2xl:pb-7.5 print:!hidden">
      <div className="px-4 2xl:px-7.5 max-w-screen-4xl mx-auto">
        <div className="bg-violet-10 rounded-t-xl md:rounded-t-2xl">
          <div
            className="
              max-w-screen-3xl mx-auto
              px-6 sm:px-8 lg:px-10 xl:px-6 2xl:px-12.5 3xl:px-20
              py-15 sm:py-25 xl:pt-30 xl:pb-36
              mb-0.5
            "
          >
            <Grid>
              <div className="col-span-12 md:col-span-4 xl:col-span-3 xl:col-start-2">
                <h2 className="text-3xl font-bold mb-12 sm:mb-4 sm:text-5xl lg:text-6xl">
                  {t('footer.faq.title')}
                </h2>
              </div>

              <div className="col-span-12 md:col-span-8 xl:col-span-6 xl:col-start-6 xl:pt-16">
                <TextAccordion namespace="footer.faq.items" />
              </div>

              {/* 
              <div className="col-span-12 md:col-span-8 md:col-start-5 xl:col-span-6 xl:col-start-6 pt-4 xl:pt-16 typography text-black text-opacity-85">
                <Trans i18nKey="footer.helpLink">
                  <Link href="/hilfe" />
                </Trans>
              </div>
              */}
            </Grid>
          </div>
        </div>
      </div>

      <div className="px-4 2xl:px-7.5 max-w-screen-4xl mx-auto">
        <div className="bg-violet-10 rounded-b-xl md:rounded-b-2xl">
          <div
            className="
              max-w-screen-3xl mx-auto
              px-6 sm:px-8 lg:px-10 xl:px-6 2xl:px-12.5 3xl:px-20
              py-15 sm:pt-20 sm:pb-14
            "
          >
            {/* 
            <Grid className="mb-13 lg:mb-24 xl:mb-34">
              <div className="col-span-12 md:col-span-6 xl:col-span-5 xl:col-start-2 typography text-opacity-100 font-medium text-xl mb-13 sm:text-4xl md:text-2xl lg:text-3xl">
                <Trans i18nKey="footer.registerForNewsletter" />
                <form
                  onSubmit={handleSubmit(onRegisterForNewsletter)}
                  noValidate
                  className="mb-8"
                >
                  <div className="mb-8 sm:flex">
                    {registeredForNewsletter === 'sent' ? (
                      <div className=" text-violet-100 mt-0 sm:mt-6 flex items-center text-base">
                        <IconCheck className="stroke-current stroke-1.5 flex-none" />
                        <span className="ml-1">
                          {t('footer.registerForNewsletterSuccessfull')}
                        </span>
                      </div>
                    ) : (
                      <>
                        <TextField
                          {...register('newsletterEmail', {
                            required: {
                              value: true,
                              message: t('comingSoonProduct.errorRequired'),
                            },
                            pattern: {
                              value: emailRegex,
                              message: t('comingSoonProduct.errorEmail'),
                            },
                          })}
                          className="w-full text-base"
                          label={t('comingSoonProduct.email')}
                          errors={errors}
                          required
                          type="email"
                        />
                        <div className="block mt-3 sm:mt-0 pt-0.5 ml-0 sm:ml-5">
                          <span className="">
                            <Button
                              type="submit"
                              loading={registeredForNewsletter === 'loading'}
                              className={` ${
                                registeredForNewsletter === 'loading'
                                  ? 'pointer-events-none'
                                  : ''
                              }`}
                            >
                              {t('footer.registerForNewsletterButton')}
                            </Button>
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
              <div className="col-span-12 md:col-span-5 md:col-start-8 lg:col-span-5 lg:col-start-8 xl:col-span-4 xl:col-start-8 typography text-black text-opacity-85">
                <Trans i18nKey="footer.feedback">
                  <a href="mailto:info@goodee.ch?subject=Feedback via Website">
                    Email
                  </a>
                </Trans>

                <div className="flex items-center mt-21">
                  <div className="text-sm text-black text-opacity-45 mr-1 typography">
                    {t('footer.paymentInfo')}
                  </div>
                  <div className="w-12 ml-4 drop-shadow-lg">
                    <img
                      className={`w-full h-full object-contain`}
                      src="/img/payment/payment-visa.png"
                      srcSet={`/img/payment/payment-visa@2x.png 2x`}
                      alt=""
                    />
                  </div>
                  <div className="w-12 ml-4 drop-shadow-lg">
                    <img
                      className={`w-full h-full object-contain`}
                      src="/img/payment/payment-mastercard.png"
                      srcSet={`/img/payment/payment-mastercard@2x.png 2x`}
                      alt=""
                    />
                  </div>
                  <div className="w-12 ml-4 drop-shadow-lg">
                    <img
                      className={`w-full h-full object-contain`}
                      src="/img/payment/payment-twint.png"
                      srcSet={`/img/payment/payment-twint@2x.png 2x`}
                      alt=""
                    />
                  </div>
                  <div className="w-12 ml-4 drop-shadow-lg">
                    <img
                      className={`w-full h-full object-contain`}
                      src="/img/payment/payment-postfinance.png"
                      srcSet={`/img/payment/payment-postfinance@2x.png 2x`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </Grid>
            */}

            <Grid>
              <div className="col-span-12 xl:col-span-10 xl:col-start-2">
                <div className="sm:flex flex-wrap items-center">
                  <nav className="mb-13 w-full sm:order-1 lg:order-2 lg:w-auto lg:mb-0">
                    <ul className="space-y-4 lg:flex lg:space-x-6 lg:space-y-0">
                      <li>
                        <Link
                          href="/impressum"
                          className="text-violet-100 h:hover:text-opacity-50 transition"
                        >
                          {t('footer.nav.imprint')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="https://privacy.migros.ch/de.html"
                          className="text-violet-100 h:hover:text-opacity-50 transition"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('footer.nav.privacy')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/pdf/AGB-Goodee.pdf"
                          className="text-violet-100 h:hover:text-opacity-50 transition"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('footer.nav.terms')}
                        </Link>
                      </li>
                      {/* 
                      <li>
                        <Link
                          href="/all-in-one-gutschein#guthaben-abfragen"
                          className="text-violet-100 h:hover:text-opacity-50 transition"
                        >
                          {t('footer.nav.checkCreditBalance')}
                        </Link>
                      </li>
                      */}
                    </ul>
                  </nav>

                  <div className="text-sm inline-flex items-start justify-center w-full mx-auto mb-13 sm:ml-auto sm:mr-0 sm:w-auto sm:order-3 sm:mb-0 lg:order-3">
                    <span className="mr-3 opacity-45 pt-0.5">Powered by</span>
                    <IconMigros className="fill-current text-inherit" />
                  </div>

                  <p className="text-black text-opacity-85 sm:order-2 lg:order-1 lg:mr-12">
                    {t('footer.copyright')}
                  </p>
                </div>
              </div>
            </Grid>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
