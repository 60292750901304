import { useEffect, useState, useRef } from "react";

export default function useBlockScroll() {
  const [blocked, setBlocked] = useState(false);
  const scrollPos = useRef(0);

  useEffect(() => {
    if (blocked) {
      scrollPos.current = window.scrollY;
      document.body.setAttribute(
        "style",
        `
          position: fixed;
          top: ${scrollPos.current * -1}px;
          left: 0;
          right: 0;
          overflow: hidden;
        `,
      );
    } else {
      document.body.removeAttribute("style");
      window.scrollTo(0, scrollPos.current);
    }
    return () => {
      document.body.removeAttribute("style");
    };
  }, [blocked]);

  return setBlocked;
}
