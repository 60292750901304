import React from 'react'
import Footer from 'components/common/Footer'
import AppbarMenu from 'components/common/AppbarMenu'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

export type LayoutProps = {
  children?: React.ReactNode
  title?: string
  description?: string
  img?: string
}

const Layout: React.FC<LayoutProps> = ({
  children,
  title = '',
  description = '',
  img,
}) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  let url = router.asPath

  const isMenubarVisible = !url.includes('/gutschein/')

  return (
    <>
      <Head>
        {t('general.title') !== 'general.title' && (
          <>
            <title>{`${t(title)} — ${t('general.title')}`}</title>
          </>
        )}
        <link
          rel="preload"
          as="font"
          href="/fonts/GT-America-Standard-Regular.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/GT-Flexa-Standard-Medium.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href="/fonts/GT-Flexa-Standard-Bold.woff2"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        {img ? (
          <meta property="og:image" content={`https://shop.goodee.ch${img}`} />
        ) : (
          <meta
            property="og:image"
            content={`https://shop.goodee.ch/img/goodee-share-image.jpg`}
          />
        )}
        {t('description') !== 'description' && (
          <meta name="description" content={`${t('description')}`} />
        )}
        <meta property="og:url" content={`https://shop.goodee.ch${url}`} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="de" />
      </Head>
      <AppbarMenu isMenuVisible={isMenubarVisible} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
