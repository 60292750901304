import React from 'react'

export type GridProps = {
  children?: React.ReactNode
  className?: string
  as?: string
}

const Grid: React.FC<GridProps> = ({
  children,
  className = '',
  as = 'div',
}) => {
  const Element: any = as
  return (
    <Element
      className={`grid grid-cols-12 gap-x-4.5 sm:gap-x-6 md:gap-x-7 lg:gap-x-8 xl:gap-x-9 ${className}`}
    >
      {children}
    </Element>
  )
}

export default Grid
