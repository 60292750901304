import { signOut, useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import MigrosIcon from 'icons/migros.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'

type Props = {
  className?: string
}

const AccountMobile = ({ className = '' }: Props) => {
  const router = useRouter()
  const { data: session } = useSession()
  const { t } = useTranslation()

  return (
    <div
      className={`flex flex-col w-full bg-white text-[#333333] font-migros ${className}`}
    >
      <h3 className="font-bold py-2 px-6">{session?.profile?.name}</h3>
      <div className="w-full h-0.5 my-3 bg-[#F5F5F5]" />
      <Link
        className="py-2 px-6 text-[#FF6600] flex flex-row items-center gap-4"
        href={`${session?.issuer}/inlink/goodee/de/welcome?return_to=${encodeURIComponent(
          'https://shop.goodee.ch/'
        )}`}
      >
        <MigrosIcon />
        {t('migrosAccount.account')}
      </Link>
      <span
        onClick={async () => {
          const token = `${session?.id_token}`
          const reqEndsessionUrl = await fetch(
            `/api/federated-logout?id_token=${token}&redirect_url=${window.location.href}`
          )
          const respEndsessionUrl = await reqEndsessionUrl.json()

          window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: 'log_out',
            ecommerce: {},
          })

          await signOut({ redirect: false })
          window.open(respEndsessionUrl.url, '_self')
        }}
        className="py-2 px-6 mb-3 cursor-pointer"
      >
        {t('migrosAccount.logOut')}
      </span>
    </div>
  )
}

export default AccountMobile
